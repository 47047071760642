import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AccountDetails from '../components/subcomponents/AccountDetails';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import {useParams} from 'react-router-dom';
import {APICall} from '../api/api';
import useStore from '../store/store';
import usePersistentStore from '../store/persistentStore';
import lscache from 'lscache';
import RealtimeDesktop from '../components/subcomponents/RealtimeDesktop';
import DecilesView from '../components/subcomponents/DecilesView';
import GeoView from '../components/subcomponents/GeoView';
import IconButton from '@material-ui/core/IconButton';
import ReactGA from 'react-ga4';
import OriginView from '../components/subcomponents/OriginView';
import CheckIcon from '@material-ui/icons/Check';
import Placeholder from '../components/misc/Placeholder';
import AccountsPropertiesSelection from '../components/subcomponents/AccountsPropertiesSelection';

const useStyles = makeStyles(theme => ({
  viewWrapper: {
    padding: 20,
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      padding: '20px 0 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const Home = props => {
  const classes = useStyles();
  const theme = useTheme();
  const defaultTab = usePersistentStore(state => state.defaultTab);
  const setDefaultTab = usePersistentStore(state => state.setDefaultTab);
  const selectedSection = useStore(state => state.selectedSection);
  const assignedGoogleProperties = useStore(state => state.assignedGoogleProperties);

  let {publisher, domain, placement} = useParams();
  const [defaultCheckBoxOpen, setDefaultCheckBoxOpen] = useState(false);

  const showSectionsTooltip = useStore(state => state.showSectionsTooltip);
  const setState = useStore(state => state.setState);

  const calculated = useStore(state => state.calculated);
  const period = useStore(state => state.period);
  const selection = useStore(state => state.selection);

  useEffect(() => {
    // Check if current account has default section enabled in ClientUI settings
    if (
      (!props.login.ssp_table_enabled && defaultTab === 2) ||
      (!props.login.geo_data && defaultTab === 3) ||
      (!props.login.sources_distribution_BETA && defaultTab === 4) ||
      (!props.login.realtime && defaultTab === 0)
    ) {
      setDefaultTab(props.login.realtime === 1 ? 0 : 1);
    } else {
      setState('selectedSection', defaultTab);
      setState('calculated', null);
    }
  }, [defaultTab]);

  useEffect(() => {
    getAssignedGoogleProperties();
  }, []);

  useEffect(() => {
    if (selectedSection !== 1 && selectedSection !== 0 && !calculated) {
      getPublisherStats();
    }
  }, [selection, period, selectedSection]);

  const getPublisherStats = async () => {
    try {
      let data = await APICall({
        endpoint: 'totalclient',
        params: `${period.startDate}/${period.endDate}${
          selection.value.publisher ? `/${selection.value.publisher}` : ``
        }${selection.value.domain ? `/${selection.value.domain}` : ``}${
          selection.value.placement ? `/${selection.value.placement}` : ``
        }`,
        options: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${props.login.reportingToken}`,
            'Cache-Control': 'max-age=0, no-cache',
          },
        },
      });

      // Add calculations for additional metrics
      data.map((day, i) => {
        // Fill missing eCPM
        data[i].eCPM = day['eCPM'] ? day['eCPM'] : 0;
      });

      let calculations = {
        dateFrom: data[0].date,
        dateTo: data[data.length - 1].date,
        rev: data.reduce((total, day) => total + parseFloat(day.Rev), 0).toFixed(2),
        imp: data.reduce((total, day) => total + parseFloat(day['All Imp']), 0).toFixed(2),
        paidImp: data.reduce((total, day) => total + parseFloat(day['Paid Imp']), 0).toFixed(2),
      };

      setState('calculated', calculations);
    } catch (error) {
      if (error.message !== 'The user aborted a request.') {
        // console.log('')
      }
    }
  };

  const getAssignedGoogleProperties = async () => {
    let assignedGoogleProperties = [];
    if (props.login.google_analytics_enabled) {
      assignedGoogleProperties = await APICall({
        endpoint: `getAssignedGoogleProperties`,
        options: {
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.login.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            'Content-Type': 'application/json',
          },
        },
      });
    }
    setState('assignedGoogleProperties', assignedGoogleProperties);
  };

  return (
    <>
      {!props.isMobile && (
        <>
          <AppBar
            position="static"
            elevation={0}
            style={{
              maxWidth: '100vw',
              position: 'sticky',
              background: theme.palette.graphColors.themeDarkGray,
              top: 65,
            }}
          >
            {props.isTablet && (
              <div
                style={{padding: '24px 24px 6px 24px', background: theme.palette.background.paper}}
              >
                <AccountsPropertiesSelection
                  {...props}
                  isTablet={props.isTablet}
                  isMobile={false}
                  comparisonEnabled={true}
                  login={props.login}
                  options={props.generateList()}
                />
              </div>
            )}
            <Tabs
              // Temporal solution for setting 1 tab as default for self-serve clients
              value={selectedSection}
              onChange={(e, value) => {
                ReactGA.event({
                  category: 'Selected section change',
                  action: 'click',
                  label: `${value}`,
                });

                setState('selectedSection', value);
                setState('calculated', null);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              //      style={{width: '100%'}}
            >
              {props.login.type !== 5 && props.login.realtime === 1 && (
                <Tooltip
                  open={!showSectionsTooltip && !props.isMobile && calculated}
                  interactive
                  arrow
                  title={
                    <div style={{margin: 10}}>
                      <Typography variant={'body1'}>
                        Find Todays Revenue, monthly breakdown and other useful features using tabs
                      </Typography>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          lscache.set('tabsTooltipSeen', 1);
                          setState('showSectionsTooltip', 1);
                        }}
                        style={{marginTop: 10, zIndex: 999999}}
                      >
                        Got it
                      </Button>
                    </div>
                  }
                >
                  <Tab label="Hourly" value={0} />
                </Tooltip>
              )}

              <Tab label="Daily" value={1} />
              {props.login.type < 5 && <Tab label="Prices" value={2} />}
              {props.login.ssp_table_enabled === 1 && <Tab label="Demand" value={3} />}
              {props.login.geo_data === 1 && <Tab label="Geo" value={4} />}
              {props.login.sources_distribution_BETA === 1 && <Tab label="Sources" value={5} />}
              {/* <Tab label="Origin" value={5} disabled /> */}
            </Tabs>

            {!props.isTablet && (
              <Tooltip
                open={showSectionsTooltip === 1 ? true : defaultCheckBoxOpen}
                onOpen={() =>
                  showSectionsTooltip !== 1
                    ? setDefaultCheckBoxOpen(true)
                    : setDefaultCheckBoxOpen(false)
                }
                onClose={() => setDefaultCheckBoxOpen(false)}
                interactive
                arrow
                title={
                  <div style={{margin: 10}}>
                    <Typography variant={'body1'}>
                      {showSectionsTooltip === 1
                        ? `Click on this icon to set the current tab as a default for your next visit.`
                        : defaultTab === selectedSection
                        ? `This tab is currently set as default`
                        : `Set as default tab`}
                    </Typography>
                    {showSectionsTooltip === 1 && (
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          lscache.set('tabsTooltipSeen', 2);
                          setState('showSectionsTooltip', 2);
                        }}
                        style={{marginTop: 10, zIndex: 999999}}
                      >
                        Got it
                      </Button>
                    )}
                  </div>
                }
              >
                <IconButton
                  onClick={() => {
                    setDefaultTab(selectedSection);

                    ReactGA.event({
                      category: 'Set as default tab',
                      action: 'click',
                      label: `${selectedSection}`,
                    });
                  }}
                  variant={'text'}
                  color={defaultTab === selectedSection ? 'primary' : 'inherit'}
                  style={{position: 'absolute', right: 25}}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            )}
          </AppBar>
        </>
      )}

      <Container
        component="main"
        maxWidth="xl"
        className={classes.viewWrapper}
        style={{opacity: showSectionsTooltip >= 2 || props.isMobile ? 1 : calculated ? 0.5 : 1}}
      >
        <Grid container className={classes.wrapper} spacing={0} direction="row">
          {assignedGoogleProperties && (
            <>
              {!props.login.menu.error && props.isMobile && (
                <>
                  <AccountDetails
                    assignedGoogleProperties={assignedGoogleProperties}
                    publisher={publisher}
                    domain={domain}
                    placement={placement}
                    login={props.login}
                  />
                </>
              )}

              {!props.login.menu.error && !props.isMobile && (
                <>
                  {selectedSection === 0 && (
                    <RealtimeDesktop isMobile={props.isMobile} login={props.login} />
                  )}

                  {selectedSection === 1 && (
                    <AccountDetails
                      assignedGoogleProperties={assignedGoogleProperties}
                      publisher={publisher}
                      domain={domain}
                      login={props.login}
                    />
                  )}
                  {selectedSection === 2 && (
                    <DecilesView
                      loadingTotals={!calculated}
                      demandOnly={false}
                      totalImpressions={parseFloat(calculated?.paidImp)}
                      totalRevenue={parseFloat(calculated?.rev)}
                      eCPM={((calculated?.rev / calculated?.paidImp) * 1000)?.toFixed(2)}
                      login={props.login}
                      menu={props.login.menu}
                    />
                  )}
                  {selectedSection === 3 && (
                    <DecilesView
                      loadingTotals={!calculated}
                      demandOnly={true}
                      totalImpressions={parseFloat(calculated?.paidImp)}
                      totalRevenue={parseFloat(calculated?.rev)}
                      eCPM={((calculated?.rev / calculated?.paidImp) * 1000)?.toFixed(2)}
                      login={props.login}
                      menu={props.login.menu}
                    />
                  )}
                  {selectedSection === 4 && (
                    <GeoView
                      loadingTotals={!calculated}
                      login={props.login}
                      totalImpressions={parseFloat(calculated?.paidImp)}
                      totalRevenue={parseFloat(calculated?.rev)}
                    />
                  )}
                  {selectedSection === 5 && (
                    <OriginView
                      assignedGoogleProperties={assignedGoogleProperties}
                      loadingTotals={!calculated}
                      login={props.login}
                      totalImpressions={parseFloat(calculated?.paidImp)}
                      totalRevenue={parseFloat(calculated?.rev)}
                    />
                  )}
                </>
              )}

              {props.login.menu.error && (
                <Container
                  maxWidth="sm"
                  style={{
                    padding: 35,
                    textAlign: 'left',
                    position: 'relative',
                  }}
                >
                  <Placeholder />
                  <Typography align={'center'}>
                    Daily data will start to collect and show up after approximately{' '}
                    <strong>24 hours</strong> after adding our ad codes (Step 5)
                  </Typography>
                </Container>
              )}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

function areEqual(prevProps, nextProps) {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  } else {
    return false;
  }
}

export default React.memo(Home, areEqual);
