import React, {useEffect, useState, useCallback} from 'react';
import {Grid, Typography, Button, Tooltip} from '@material-ui/core';
import useStore from '../../../store/store';
import adManagerLogo from '../../../components/img/ad-manager-logo.svg';
import {APICall} from '../../../api/api';
import {Alert} from '@material-ui/lab';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import usePublishers from '../../../hooks/usePublishers';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import FadeIn from 'react-fade-in/lib/FadeIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  itemWrapper: {
    padding: '10px 15px',
    border: '1px solid #7979793b',
    marginBottom: 15,
    borderRadius: 4,
    minHeight: 58,
    '&:hover': {
      background: theme.palette.graphColors.sorted,
    },
  },
  itemWrapperSelected: {
    padding: '10px 15px',
    border: '1px solid #7979793b',
    marginBottom: 15,
    minHeight: 58,
    borderRadius: 4,
    boxShadow: '0 0 25px #1cbc9c80',
    color: 'white!important',
    background: theme.palette.graphColors.refreshRate,
  },
  adManagerLogo: {
    height: 22,
    top: 4,
    position: 'relative',
    marginRight: 15,
  },
  networkName: {
    fontWeight: 600,
  },
  networkDetail: {
    fontSize: 12,
    borderRadius: 4,
    background: theme.palette.graphColors.themeLightGrayOpaque,
    display: 'inline-block',
    padding: '3px 6px',
    marginRight: 5,
    position: 'relative',
  },
  networkDetailTest: {
    fontSize: 12,
    borderRadius: 4,
    display: 'inline-block',
    padding: '1px 3px',
    border: `1px solid ${theme.palette.graphColors.themeGray}`,
    marginRight: 5,
    marginLeft: 5,
  },
  hidden: {
    display: 'none',
  },
  errorIcon: {
    top: 0,
    color: 'red',
    position: 'absolute',
    fontSize: 26,
    right: -34,
  },
  selectedIcon: {
    position: 'relative',
    marginLeft: 6,
    fontSize: 24,
  },
}));

export function NetworksList({
  allNetworks,
  setAllNetworks,
  publisher,
  assignedAuth,
  loading,
  setLoading,
  assignedNetwork,
  setAssignedNetwork,
}) {
  const [error, setError] = useState(null);
  const [listExpanded, setListExpanded] = useState(true);
  const auth = useStore(state => state.auth);
  const classes = useStyles();
  const {getPublishers} = usePublishers();

  const getAllNetworks = useCallback(async () => {
    setAllNetworks(null);
    setLoading(true);
    setError(null);

    let allNetworks = await APICall({
      endpoint: 'getAllNetworks',
      params: assignedAuth.id,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (allNetworks.success) {
      setAllNetworks(allNetworks.networks);
    } else {
      // Handle no networks error case
      if (allNetworks.error.includes('rval')) {
        allNetworks.error =
          'There are no networks associated with this email account in Google. Please check which email has admin rights in your Google Ad Manager.';
      }

      setError(allNetworks.error);
    }

    setLoading(false);
  }, [auth, assignedAuth, setAllNetworks, setLoading]);

  useEffect(() => {
    getAllNetworks();
  }, [assignedAuth, getAllNetworks]);

  useEffect(() => {
    if (allNetworks) {
      let assignedNetwork = allNetworks?.find(
        network => +network.networkCode === +publisher.networkCode
      );
      setAssignedNetwork(assignedNetwork);
      if (assignedNetwork) {
        setListExpanded(false);
      }
    }
  }, [allNetworks, publisher.networkCode, setAssignedNetwork]);

  const assignNetwork = async network => {
    setLoading(true);
    setError(null);

    let assignNetwork = await APICall({
      endpoint: 'assignNetwork',
      options: {
        method: 'post',
        body: JSON.stringify({
          publisherId: publisher.publisherId,
          networkCode: network.networkCode,
          currency: network.currencyCode,
          timeZone: network.timeZone,
          displayName: network.displayName,
        }),
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    if (assignNetwork.success) {
      setAssignedNetwork(network);
      getPublishers();
    } else {
      setError(assignNetwork.error);
    }

    setLoading(false);
  };

  // Used internally for testing purposes
  const createTestNetwork = async () => {
    setLoading(true);
    setError(null);

    let testNetwork = await APICall({
      endpoint: 'createTestNetwork',
      params: assignedAuth.id,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (assignNetwork.success) {
      getPublishers();
    } else {
      setError(testNetwork.error);
    }

    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom style={{fontWeight: 600}}>
          Networks list
        </Typography>

        <Grid item xs={12}>
          {!allNetworks && !error && (
            <Skeleton height={300} variant="rect" style={{borderRadius: 4}} />
          )}

          {allNetworks?.length === 0 && <div>Account has no Ad Manager networks</div>}

          {allNetworks?.length &&
            allNetworks
              // Filter networks by assigned network if list is not expanded
              .filter(network =>
                listExpanded ? true : +network.networkCode === +assignedNetwork?.networkCode
              )
              .map((network, i) => (
                <FadeIn delay={200 * (1 + i)}>
                  <>
                    <Grid
                      container
                      className={
                        publisher.order_generator_job_id &&
                        +network.networkCode !== +assignedNetwork?.networkCode
                          ? classes.hidden
                          : classes.itemWrapper
                      }
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.networkName}>
                          <img
                            src={adManagerLogo}
                            className={classes.adManagerLogo}
                            alt="Google Ad Manager"
                          />
                          {network.displayName}
                          {network.isTest && <div className={classes.networkDetailTest}>Test</div>}
                          <Typography variant="caption"> ({network.networkCode})</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="caption">
                          <div className={classes.networkDetail}>{network.timeZone}</div>
                          <div className={classes.networkDetail}>
                            {network.currencyCode}
                            {!network.allowedCurrency && (
                              <Tooltip arrow title={`Network's currency is not supported.`}>
                                <ReportProblemIcon className={classes.errorIcon} />
                              </Tooltip>
                            )}
                          </div>
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {+network.networkCode !== +publisher.networkCode && (
                          <Button
                            fullWidth
                            variant={'text'}
                            color={'primary'}
                            disabled={
                              loading ||
                              publisher.order_generator_job_id ||
                              !network.allowedCurrency ||
                              // Special case when some networks doesn't have name.
                              !network.displayName
                            }
                            onClick={() => assignNetwork(network)}
                          >
                            {!network.displayName ? 'No network name' : 'Select network'}
                          </Button>
                        )}
                        {+network.networkCode === +publisher.networkCode && (
                          <Button fullWidth variant={'text'} color={'primary'} disabled>
                            Selected{' '}
                            <CheckCircleIcon
                              fontSize={'inherit'}
                              color={'secondary'}
                              className={classes.selectedIcon}
                            />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                </FadeIn>
              ))}

          {!listExpanded && allNetworks?.length > 1 && (
            <Button color={'primary'} onClick={() => setListExpanded(true)}>
              Show all networks ({allNetworks.length})
            </Button>
          )}
          {/* <Button
          fullWidth
          variant={'text'}
          color={'primary'}
          disabled={loading}
          onClick={() => createTestNetwork()}
        >
          Create test network
        </Button> */}
        </Grid>
        {error && (
          <Grid container>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
}
