import React, {useEffect, useState, useCallback} from 'react';
import {Grid, Button, CircularProgress, Typography, Tooltip} from '@material-ui/core';
import useStore from '../../../store/store';
import {useGoogleLogin, hasGrantedAnyScopeGoogle} from '@react-oauth/google';
import googleGLogo from '../../../components/img/google_g_logo.svg';
import {APICall} from '../../../api/api';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import {steps} from './helpers/initializationSteps';
import {Info} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  signInWithGoogle: {
    padding: 10,
    marginBottom: 10,
    marginTop: 5,
  },
  icon: {
    top: 4,
    height: 22,
    position: 'relative',
    marginRight: 12,
  },
  iconDisabled: {
    top: 4,
    height: 22,
    position: 'relative',
    marginRight: 12,
    filter: 'saturate(0)',
  },
  promoImage: {
    background: `url(https://setupad.com/wp-content/themes/setupad/assets/img/landing/setupad-prebid-self-serve-main.svg)`,
    // Alternative illustration
    //background: `url(https://setupad.com/wp-content/themes/setupad/assets/img/about/footer-handshake.png)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  stepWrapper: {
    marginBottom: theme.spacing(1),
    display: 'inline-block',
    borderRadius: '100%',
    border: `1px solid #1895a166`,
    color: theme.palette.primary.main,
    fontWeight: 600,
    height: 26,
    width: 26,
    textAlign: 'center',
    lineHeight: '24px',
    fontSize: 14,
    marginRight: 8,
    cursor: 'help',
  },
  infoIcon: {opacity: 0.5, left: 5, top: 4, position: 'relative', cursor: 'help'},
}));

export function LoginStep({publisher, assignedAuth, setAssignedAuth}) {
  const [loading, setLoading] = useState(null);
  const auth = useStore(state => state.auth);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const getGoogleAuth = useCallback(
    async (response = null) => {
      setLoading(true);
      setError(null);

      if (!response) {
        // get assigned Google auth from our API
        let assignAuth = await APICall({
          endpoint: 'getAssignedSelfServeAuth',
          params: publisher.gam_auth_id,
          options: {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        });

        if (assignAuth.success) {
          setAssignedAuth(assignAuth.user);
        } else {
          setError({
            type: 'auth',
            message: assignAuth.error,
          });
        }
      } else {
        // Exchange google response code to access tokens, and assign to current account / publisher
        let assignAuth = await APICall({
          endpoint: 'assignSelfServeAuth',
          options: {
            method: 'post',
            body: JSON.stringify({
              googleCode: response,
              publisherId: publisher.publisherId,
              currency: publisher.currency,
            }),
            headers: {
              Authorization: `Bearer ${auth.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              'Content-Type': 'application/json',
            },
          },
        });

        if (assignAuth.success) {
          setAssignedAuth(assignAuth.user);
        } else {
          setError({
            type: 'auth',
            message: assignAuth.error,
          });
        }
      }

      setLoading(false);
    },
    [auth, publisher.gam_auth_id, publisher.publisherId, publisher.currency]
  );

  useEffect(() => {
    if (publisher.gam_auth_id) {
      getGoogleAuth();
    }
  }, [auth, getGoogleAuth, publisher.gam_auth_id]);

  const googleLoginHook = useGoogleLogin({
    flow: 'auth-code',
    scope: 'profile email https://www.googleapis.com/auth/dfp',
    onSuccess: async tokenResponse => {
      // Check if user has granted dfp auth scope
      if (hasGrantedAnyScopeGoogle(tokenResponse, 'https://www.googleapis.com/auth/admanager')) {
        getGoogleAuth(tokenResponse);
      } else {
        setError({
          type: 'auth',
          message:
            'You must grant access to Google Ad Manager to use Setupad self-serve. Please click on "Sign In" again to agree with the required permissions.',
        });
      }
    },
  });

  return (
    <Grid item xs={12}>
      {!assignedAuth && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant={'h6'} gutterBottom>
              Initialize Setupad Prebid Self-Serve
            </Typography>
            {Object.keys(steps).map(i => (
              <>
                <Typography variant={'body1'} align={'left'} display={'block'} gutterBottom>
                  <div
                    className={classes.stepWrapper}
                    style={
                      !steps[i].active
                        ? {
                            filter: 'saturate(0)',
                          }
                        : null
                    }
                  >
                    {i}
                  </div>

                  {steps[i].title}
                  <Tooltip
                    title={<Typography variant={'caption'}>{steps[i].tooltipText}</Typography>}
                    arrow
                  >
                    <Info className={classes.infoIcon} fontSize={'small'} />
                  </Tooltip>
                  {i === '1' && (
                    <Button
                      fullWidth
                      className={classes.signInWithGoogle}
                      startIcon={
                        loading ? (
                          <CircularProgress size={16} />
                        ) : (
                          <img src={googleGLogo} alt={'Google Login'} style={{height: 16}} />
                        )
                      }
                      onClick={googleLoginHook}
                      variant={'contained'}
                      color={'primary'}
                      disabled={loading}
                    >
                      {!loading ? 'Sign in with Google' : 'Authenticating...'}
                    </Button>
                  )}
                </Typography>
              </>
            ))}
          </Grid>
          <Grid item xs={6} className={classes.promoImage} />
        </Grid>
      )}

      {assignedAuth && (
        <Grid container>
          <Grid item xs={6}>
            <Button
              className={classes.signInWithGoogle}
              fullWidth
              startIcon={
                <img
                  src={assignedAuth.picture}
                  style={{height: 22, width: 22, borderRadius: '50%'}}
                  alt={assignedAuth.name}
                />
              }
              onClick={googleLoginHook}
              variant={'outlined'}
              disabled={loading || (publisher.order_generator_job_id && !publisher.gam_initialized)}
            >
              {assignedAuth.name}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.signInWithGoogle}
              fullWidth
              onClick={googleLoginHook}
              variant={'text'}
              color={'primary'}
              disabled={loading || (publisher.order_generator_job_id && !publisher.gam_initialized)}
            >
              Sign in with a different Google account
            </Button>
          </Grid>
        </Grid>
      )}

      {error?.type === 'auth' && <Alert severity="error">{error?.message}</Alert>}
    </Grid>
  );
}
