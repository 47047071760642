import React, {useEffect, useState, useCallback} from 'react';
import {Grid, Typography, Button, Link, IconButton, Tooltip, TextField} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import useStore from '../../../store/store';
import {APICall} from '../../../api/api';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import {SiteStatus} from '../../helpers/MCMstatusDecoder';
import {NewDomainDialog} from './NewDomainDialog';
import {Launch} from '@material-ui/icons';
import useRefreshMenu from '../../../hooks/useRefreshMenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  listWrapper: {
    // maxHeight: 400,
    overflowX: 'hidden',
    borderRadius: 8,
  },
  itemWrapper: {
    padding: '10px 15px',
    border: '1px solid #7979793b',
    marginBottom: 15,
    borderRadius: 4,
    minHeight: 58,
    '&:hover': {
      background: theme.palette.graphColors.sorted,
    },
  },
  itemWrapperSelected: {
    border: '1px solid #7979793b',
    marginBottom: 15,
    minHeight: 58,
    borderRadius: 4,
    padding: '10px 15px',
    '&:hover': {
      background: theme.palette.graphColors.sorted,
    },
  },
  siteName: {
    fontWeight: 600,
  },
  hidden: {
    display: 'none',
  },
  domainImage: {
    height: 20,
    width: 20,
    position: 'relative',
    top: 5,
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '80%',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 15,
  },
  launchIcon: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  successIcon: {
    position: 'relative',
    marginLeft: 6,
    fontSize: 24,
  },
}));

export function SitesList({publisher}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const {refreshMenu} = useRefreshMenu();
  const auth = useStore(state => state.auth);
  const classes = useStyles();
  const [sites, setSites] = useState(null);
  const domains = useStore(state => state.domains);
  const publisherDomains = domains.filter(domain => domain.publisherId === publisher.publisherId);
  const [search, setSearch] = useState('');

  const getSitesList = useCallback(async () => {
    setSites(null);
    setLoading(true);
    setError(null);
    setSearch('');

    let response = await APICall({
      endpoint: 'getSitesList',
      params: `${publisher.gam_auth_id}/${publisher.networkCode}`,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      },
    });

    if (response.success) {
      setSites(response);
    } else {
      // Handle no networks error case
      setError(response.error);
    }

    setLoading(false);
  }, [auth, setLoading, publisher]);

  useEffect(() => {
    getSitesList();
  }, [getSitesList]);

  const importDomain = async site => {
    setLoading(true);
    setError(null);
    try {
      let importDomain = await APICall({
        endpoint: 'importDomain',
        options: {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Cache-Control': 'max-age=0, no-cache',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            publisher_id: publisher.publisherId,
            url: site.url,
            siteId: site.id,
          }),
        },
      });

      if (!importDomain.success) {
        throw new Error(importDomain.error);
      } else {
        // Refresh menu
        refreshMenu();
      }
    } catch (e) {
      setError('Failed to import domain. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography style={{fontWeight: 600}}>Sites list</Typography>
      </Grid>

      {sites?.success && !sites?.data && (
        <Grid item xs={12}>
          <Alert
            severity="info"
            action={
              <Button
                color="inherit"
                variant={'outlined'}
                style={{marginRight: 40, fontWeight: 600}}
                onClick={getSitesList}
              >
                Check again
              </Button>
            }
          >
            <AlertTitle>Selected network has no sites in Ad Manager inventory.</AlertTitle>
          </Alert>
        </Grid>
      )}

      {!sites && !error && (
        <Grid item xs={12}>
          <Skeleton height={100} variant="rect" style={{borderRadius: 4}} />
        </Grid>
      )}

      {sites?.success && sites?.data?.length && (
        <>
          {sites.data.length > 6 && (
            <Grid
              container
              //  className={classes.itemWrapper}
              justifyContent="center"
              alignItems="center"
              style={{padding: 8}}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Search"
                onChange={({target: {value}}) => setSearch(value)}
                helperText={
                  sites.data.filter(site => (search.length > 0 ? site.url.includes(search) : true))
                    .length === 0
                    ? 'No results found'
                    : ''
                }
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.listWrapper}>
            {console.log('debug publisherDomains', publisherDomains)}
            {sites.data
              .filter(site => (search.length > 0 ? site.url.includes(search) : true))
              .map((site, i) => {
                const isImported = publisherDomains.find(domain => domain.siteId === site.id);
                return (
                  <Grid
                    key={i}
                    container
                    className={isImported ? classes.itemWrapperSelected : classes.itemWrapper}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.siteName}>
                        <div
                          className={classes.domainImage}
                          style={{
                            backgroundImage: `url(https://www.google.com/s2/favicons?domain=https://${site.url}&sz=256)`,
                          }}
                        />
                        {site.url} <Typography variant="caption">({site.id})</Typography>
                        <Tooltip title={'Open in new tab'} arrow>
                          <IconButton href={`https://${site.url}`} target={'_blank'}>
                            <Launch className={classes.launchIcon} />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <SiteStatus site={site} />
                    </Grid>
                    <Grid item xs={3}>
                      {!isImported && (
                        <Button
                          fullWidth
                          variant={'text'}
                          color={'primary'}
                          disabled={loading || publisher.order_generator_job_id}
                          onClick={() => importDomain(site)}
                        >
                          Import to Setupad
                        </Button>
                      )}
                      {isImported && (
                        <Button fullWidth variant={'text'} color={'primary'} disabled>
                          Imported{' '}
                          <CheckCircleIcon
                            fontSize={'inherit'}
                            color={'secondary'}
                            className={classes.successIcon}
                          />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}

      {sites &&
        !error &&
        (!publisher.order_generator_job_id || publisher.gam_initialized === 1) && (
          <NewDomainDialog
            publisher={publisher}
            loading={loading}
            setLoading={setLoading}
            sites={sites.data}
            importDomain={importDomain}
          />
        )}

      {error && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                variant={'outlined'}
                style={{marginRight: 40, fontWeight: 600}}
                onClick={getSitesList}
                disabled={loading}
              >
                Check again
              </Button>
            }
          >
            <AlertTitle>{error}</AlertTitle>
            {error.includes('API Access disabled') && (
              <>
                How to turn on API access:{' '}
                <Link
                  href="https://support.google.com/admanager/answer/3088588?hl=en"
                  target={'_blank'}
                  color="primary"
                >
                  [link]
                </Link>
              </>
            )}
            {error.includes('Permission denied') && (
              <>
                Your account doesn't have permission to view and create sites on this network:{' '}
                <Link
                  href="https://support.google.com/admanager/answer/9154293?hl=en#zippy=%2Cview-sites"
                  target={'_blank'}
                  color="primary"
                >
                  [link]
                </Link>
              </>
            )}
          </Alert>
        </Grid>
      )}
    </>
  );
}
