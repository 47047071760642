import React, {useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {APICall} from '../../api/api';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles/index';
import millify from 'millify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FadeIn from 'react-fade-in';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import useStore from '../../store/store';
import AnalyticsIcon from './../img/ic_analytics.svg';
import {PeriodPicker} from './PeriodPicker';
import {Alert, Skeleton} from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  buttons: {
    margin: theme.spacing(2),
  },
  sorted: {
    background: theme.palette.graphColors.sorted,
  },
  alert: {
    marginBottom: 20,
  },
}));

let mapping = {
  // Key - whats returned from API, value - how we map to your origin report response
  ga4: {
    Direct: 'direct',
    'Organic Search': 'organic',
    'Organic Video': 'organic',
    'Organic Social': 'social',
    Referral: 'referral',
  },
  ga3: {
    Direct: 'direct',
    'Organic Search': 'organic',
    Social: 'social',
    Referral: 'referral',
  },
};

const OriginView = React.memo(props => {
  const classes = useStyles();
  const [originData, setOriginData] = React.useState(false);
  const [analyticsProperty, setAnalyticsProperty] = React.useState(false);
  const [analyticsData, setAnalyticsData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [sort, setSort] = React.useState({key: 'rev', direction: 'down'});
  const abortController = new AbortController();

  const selection = useStore(state => state.selection);
  const period = useStore(state => state.period);

  const theme = useTheme();

  useEffect(() => {
    if (analyticsProperty) {
      getAnalyticsStatsForProperty();
    }
  }, [analyticsProperty]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    setOriginData(false);
    setAnalyticsProperty(null);
    setAnalyticsData(null);
    getOriginData();

    return () => abortController.abort();
  }, [selection.value, period]);

  const getOriginData = async () => {
    let data = await APICall({
      endpoint: 'getOriginData',
      options: {
        method: 'POST',
        body: JSON.stringify({
          selection: selection,
          period: period,
        }),
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
    setLoading(false);
    if (data.success) {
      setError(null);
      setOriginData(data.originDataClient);
      let analyticsProperty = props.assignedGoogleProperties.find(
        property => property.domainId == selection.value.domain && !selection.value.placement
      );
      if (analyticsProperty) {
        setAnalyticsProperty(analyticsProperty);
      }
    } else {
      console.log('debug originData', data);
      setError('Error getting Origin data');
    }
  };

  let getAnalyticsStatsForProperty = async () => {
    try {
      setAnalyticsData(
        await APICall({
          endpoint: 'getAnalyticsDataForOriginSources',
          options: {
            signal: abortController.signal,
            method: 'POST',
            body: JSON.stringify({
              analyticsProperty: analyticsProperty,
              dateFrom: period.startDate,
              dateTo: period.endDate,
            }),
            headers: {
              Authorization: `Bearer ${props.login.token}`,
              'Cache-Control': 'max-age=0, no-cache',
              'Content-Type': 'application/json',
            },
          },
        })
      );
    } catch (error) {
      if (error.message !== 'The user aborted a request.') {
        setError(`Error, ${error.message}`);
        setLoading(false);
      }
    }
  };

  const changeSort = key => {
    setSort(prevSort => {
      return {
        key: key,
        direction: prevSort.direction === 'down' ? 'up' : 'down',
      };
    });
  };

  let getMappingForDebug = origin => {
    let key = Object.keys(mapping[analyticsData.ga4 ? 'ga4' : 'ga3']).find(key => key === origin);

    return key ? key : 'Unknown';
  };

  let getPageviewsMappedByOrigin = origin => {
    if (!analyticsData?.success) {
      return null;
    }
    let pageviews = 0;

    let apiVersion = analyticsData.ga4 ? 'ga4' : 'ga3';

    // If origin is 'unknown' we sum up all unmapped sources
    if (origin === 'unknown') {
      // Unmapped analytics data
      let unmappedAnalyticsData = Object.keys(analyticsData.data).filter(
        key => !mapping[apiVersion].hasOwnProperty(key)
      );
      pageviews = unmappedAnalyticsData.reduce((total, key) => total + analyticsData.data[key], 0);
    } else {
      // Map sources from our report to GA sources
      // First get required keys from mapping
      let keys = Object.keys(mapping[apiVersion]).filter(
        key => mapping[apiVersion][key] === origin
      );

      // Then add up data from GA by required keys
      pageviews = keys.reduce((total, key) => total + (analyticsData.data[key] ?? 0), 0);
    }
    return pageviews;
  };

  const generateTable = () => {
    // Get total impressions from our database
    let impressionsWithoutRefreshes = props.totalImpressions;

    // Get total impressions from google report
    let totalImpressions = originData.reduce((total, country) => total + country.imp, 0).toFixed(2);

    // Calculate how much times more impressions there are without refreshes
    let impRatio = totalImpressions / impressionsWithoutRefreshes;

    // Get total revenue
    let totalRevenue = originData.reduce((total, country) => total + country.rev, 0).toFixed(2);

    // Get total pageviews from GA
    let totalPageviews = analyticsData?.success
      ? Object.keys(analyticsData.data).reduce((total, key) => total + analyticsData.data[key], 0)
      : 0;

    // Add recalculations
    let data = originData.map(row => {
      let adjustedImpressions = row.imp / impRatio;
      return {
        ...row,
        imp: adjustedImpressions,
        eCPM: (row.rev / adjustedImpressions) * 1000,
        revShare: ((row.rev * 100) / totalRevenue).toFixed(2),
        impShare: ((adjustedImpressions * 100) / props.totalImpressions).toFixed(2),
        pageviews: getPageviewsMappedByOrigin(row.origin),
      };
    });

    // Get totals for table
    totalImpressions = data.reduce((total, country) => total + country.imp, 0).toFixed(2);

    // Sort data
    let sortedData = Object.entries(data).sort((a, b) => {
      return sort.direction === 'down'
        ? b[1][sort.key] - a[1][sort.key]
        : a[1][sort.key] - b[1][sort.key];
    });

    return (
      <>
        {analyticsData && analyticsData.error && (
          <Alert className={classes.alert} severity="error">
            Google Analytics error: {analyticsData.error}
          </Alert>
        )}

        {props.assignedGoogleProperties &&
          props.assignedGoogleProperties.length > 0 &&
          (!selection.value.domain || selection.value.placement) && (
            <Alert
              className={classes.alert}
              severity="info"
              iconMapping={{
                info: <img src={AnalyticsIcon} style={{position: 'relative', height: 20}} />,
              }}
            >
              Select a Domain to view Google Analytics data
            </Alert>
          )}

        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontWeight: 'bold'}}>Origin</StyledTableCell>
                {analyticsProperty && !analyticsData?.error && (
                  <StyledTableCell
                    onClick={() => changeSort('pageviews')}
                    align="right"
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      borderBottom: `3px solid ${theme.palette.graphColors.sRPM}`,
                    }}
                  >
                    {sort.key === 'pageviews' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    <img
                      src={AnalyticsIcon}
                      style={{position: 'relative', top: 1, left: -8, height: 13}}
                    />
                    Pageviews
                  </StyledTableCell>
                )}
                <StyledTableCell
                  onClick={() => changeSort('imp')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'imp' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Impressions
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('impShare')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'impShare' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Impressions share
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('eCPM')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.eCPM}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'eCPM' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    eCPM
                  </div>
                </StyledTableCell>

                <StyledTableCell
                  onClick={() => changeSort('rev')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.revenue}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'rev' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Revenue
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('revShare')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'revShare' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Revenue share
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('viewability')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px dashed ${theme.palette.graphColors.viewability}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'viewability' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Viewability
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('ctr')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px dashed ${theme.palette.graphColors.CTR}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'ctr' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    CTR
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(row => {
                return (
                  <TableRow key={row[0]}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{textTransform: 'capitalize', fontWeight: 'bold'}}
                    >
                      {row[1].origin}
                    </StyledTableCell>
                    {analyticsProperty && (
                      <StyledTableCell
                        align="right"
                        className={sort.key === 'pageviews' ? classes.sorted : ''}
                      >
                        {analyticsData && (
                          <>
                            {millify(row[1].pageviews)}{' '}
                            <Typography variant={'caption'}>
                              ({((row[1].pageviews * 100) / totalPageviews).toFixed(2)}
                              %)
                            </Typography>
                          </>
                        )}
                        {!analyticsData && <Skeleton variant="text" />}
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'imp' ? classes.sorted : ''}
                    >
                      {millify(row[1].imp.toFixed())}
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      className={sort.key === 'impShare' ? classes.sorted : ''}
                    >
                      {row[1].impShare}%
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'eCPM' ? classes.sorted : ''}
                    >
                      {millify(row[1].eCPM)}€
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      className={sort.key === 'rev' ? classes.sorted : ''}
                    >
                      {row[1].rev.toFixed(2)}€
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'revShare' ? classes.sorted : ''}
                    >
                      {row[1].revShare}%
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'viewability' ? classes.sorted : ''}
                    >
                      {millify(row[1].viewability)}%
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'ctr' ? classes.sorted : ''}
                    >
                      {millify(row[1].ctr)}%
                    </StyledTableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Total
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" align="right">
                  {analyticsData && millify(totalPageviews)}
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">
                  {millify(totalImpressions)}
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                <TableCell style={{fontWeight: 'bold'}} align="right">
                  {totalRevenue}€
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div style={{padding: '0 40px 40px 40px', width: '100%', textAlign: 'center'}}>
      <Typography variant={'h6'} style={{marginBottom: 10}} align={'left'}>
        Distribution by sources
        <Tooltip arrow interactive title={`More information coming soon`}>
          <InfoIcon
            style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
            fontSize={'small'}
          />
        </Tooltip>
        <Typography variant={'caption'} color={'primary'} style={{marginLeft: 20}}>
          Development in progress 🔧
        </Typography>
      </Typography>

      <Grid item xs={12} style={{marginBottom: 15, textAlign: 'left'}}>
        <PeriodPicker isMobile={props.isMobile} />
      </Grid>

      {(!originData || props.loadingTotals) && !error && (
        <>
          <FadeIn>
            <Typography variant={'caption'}>Loading Origin data</Typography>
            <br />
            <CircularProgress color="primary" />
          </FadeIn>
        </>
      )}
      {error && (
        <>
          <Typography variant={'caption'}>{error}</Typography>
        </>
      )}

      {originData && !props.loadingTotals && (
        <>
          <FadeIn>{generateTable()}</FadeIn>
        </>
      )}

      {props.login.wb_auth && analyticsData?.success && (
        <>
          <Grid item xs={12} style={{marginTop: 15}}>
            <Alert className={classes.alert} severity="warning">
              <Typography variant={'subtitle'}>
                Table below is only visible for Account Managers.
              </Typography>
            </Alert>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{fontWeight: 'bold'}} align="left">
                      Analytics origin
                    </StyledTableCell>
                    <StyledTableCell style={{fontWeight: 'bold'}} align="right">
                      Value
                    </StyledTableCell>
                    <StyledTableCell style={{fontWeight: 'bold'}} align="right">
                      Added to origin
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(analyticsData.data).map(origin => (
                    <TableRow key={origin} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell component="th" scope="row">
                        {origin}
                      </TableCell>
                      <TableCell align="right">{millify(analyticsData.data[origin])}</TableCell>
                      <TableCell align="right">{getMappingForDebug(origin)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </div>
  );
});

export default React.memo(OriginView);
