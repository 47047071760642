// Fonts
import InterLight from './../../fonts/InterLight.woff2';
import InternRegular from './../../fonts/InterRegular.woff2';
import {createMuiTheme} from '@material-ui/core/styles';

const graphColorsDark = {
  sorted: '#2d29314d',
  themeDarkGray: '#2f2a32',
  themeGray: '#919191',
  themeLightGray: '#afafaf',
  themeLightGrayOpaque: 'rgba(175,175,175, .2)',
  revenue: '#56cb80',
  refreshRevenue: 'rgba(86,203,128, 0.6)',
  eCPM: '#21B5C4',
  rCPM: 'rgba(26,182,197,0.6)',
  CTR: '#8e39e0',
  uRPM: '#b95d09',
  sRPM: '#F57E02',
  pvRPM: '#FFC517',
  viewability: '#e09354',
  refreshRCPM: 'rgba(230, 126, 34, 0.3)',
  refreshECPM: 'rgba(230, 126, 34, 0.3)',
  themeAlmostWhite: '#c7c7c7',
  themeAlmostWhiteOpaque: 'rgba(199, 199, 199, .7)',
  refreshRate: '#1abc9c',
  transparentBackground: 'rgba(0,0,0,0.9)',
};

const graphColorsLight = {
  sorted: '#e4e4e42e',
  themeDarkGray: '#e4e4e4',
  themeGray: '#919191',
  themeLightGray: '#afafaf',
  themeLightGrayOpaque: 'rgba(175,175,175, .2)',
  revenue: '#56cb80',
  refreshRevenue: 'rgba(86,203,128, 0.6)',
  eCPM: '#21B5C4',
  rCPM: 'rgba(62,134,238, 0.6)',
  CTR: '#8e39e0',
  uRPM: '#b95d09',
  sRPM: '#F57E02',
  pvRPM: '#FFC517',
  viewability: '#e09354',
  refreshRCPM: '#e67e22',
  refreshECPM: '#e67e22',
  themeAlmostWhite: '#c7c7c7',
  themeAlmostWhiteOpaque: 'rgba(199, 199, 199, .7)',
  refreshRate: '#1abc9c',
  transparentBackground: 'rgba(255,255,255,.9)',
};

const InterRegular = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  //  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Inter'),
      local('Inter-light'),
      url(${InternRegular}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const InterLightFont = {
  fontFamily: 'Inter-Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Inter'),
      local('Inter-light'),
      url(${InterLight}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const lightTheme = createMuiTheme({
  name: 'lightTheme',
  typography: {
    fontFamily: "'Inter', sans-serif",
    h6: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        // borderRadius: 24,
        // padding: 10
        //   fontWeight: '600!important'
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'white',
        color: 'black',
        display: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [InterRegular],
        '*::-webkit-scrollbar': {
          width: 6,
          height: 4,
        },
        '*::-webkit-scrollbar-track': {
          opacity: 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          background: graphColorsDark.themeGray,
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color, box-shadow',
        },
      },
    },
  },
  palette: {
    // type: 'dark',
    primary: {
      light: '#21B5C4',
      main: '#21B5C4',
      dark: '#21B5C4',
      contrastText: '#fff',
    },
    secondary: {
      light: '#dde2dd',
      main: '#56cb80',
      dark: '#292929',
      contrastText: '#fff',
    },
    graphColors: graphColorsLight,
  },
});

const darkTheme = createMuiTheme({
  name: 'darkTheme',
  typography: {
    fontFamily: "'Inter', sans-serif",
    h6: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        // borderRadius: 24,
        // padding: 10
        //   fontWeight: '600!important'
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgb(0 2 5 / 88%)',
        borderRadius: 6,
      },
      arrow: {
        color: 'rgb(0 2 5 / 88%)',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'white',
        color: 'black',
        display: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: 4,
          height: 4,
        },
        '*::-webkit-scrollbar-track': {
          opacity: 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          background: graphColorsDark.themeGray,
        },
        '@font-face': [InterRegular],
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color, box-shadow',
        },
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#21B5C4',
      main: '#21B5C4',
      dark: '#1895a1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#dde2dd',
      main: '#56cb80',
      dark: '#0f0f0f',
      contrastText: '#fff',
    },
    background: {
      paper: '#1b181d',
      default: '#110f12',
    },
    graphColors: graphColorsDark,
  },
});

export {lightTheme, darkTheme};
